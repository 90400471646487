import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import Avatar from "react-avatar";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Services
import api from "../../services/api";

// Context
import { useAuth } from "../../context/auth";

// Components
import { Header } from "../../components/ui/header";
import { Button } from "../../components/forms/Button";
import { Input } from "../../components/forms/Input";

//images
import IlustrationStepOne from "../../assets/images/anamnese/anamnese_step_1.svg";

//icons
import ArrowLeft from "../../assets/icons/arrow-left-icon.svg";
import ArrowRight from "../../assets/icons/arrow-right-icon.svg";

// Form schema
const schema = yup
  .object({
    goal: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo objetivo é obrigatório",
        (field) => field !== "empty-disabled"
      ),
    // regular: yup.string().required("Campo aniversário é obrigatório"),
  })
  .required();

export default function One() {
  //React hook form declarations
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Hooks
  const { user: sessionUser } = useAuth();
  const history = useHistory();

  //Submit form
  const onSubmit = async ({ goal, regular, weekly_count }) => {
    const formData = {
      goal: goal,
      regular: regular,
      weekly_count: weekly_count,
    };

    try {
      console.log(formData);
    } catch (error) {
      console.log({ error });
    } finally {
      console.log("finnaly");
    }
  };

  const [regular, setRegular] = useState("sim");

  console.log(sessionUser);
  return (
    <section className="anamnese">
      <Header
        icon={ArrowLeft}
        title="Sair"
        className="anamnese__header"
        handleButtonClick={() => history.push("/feed")}
      />
      <div className="anamnese__ilustration">
        <img src={IlustrationStepOne} className="ilustration_image" />
      </div>
      <div className="anamnese__questions">
        <div className="anamnese__first_question">
          <form className="anamnese__form" onSubmit={handleSubmit(onSubmit)}>
            <Input.Select
              placeholder="Qual o seu objetivo com a Smart Training?"
              name="goal"
              leftElement={<img src={ArrowRight} alt="Arrow icon" />}
              register={register}
              defaultValue="empty-disabled"
            >
              <option value="slimming">Emagrecimento</option>
              <option value="health">Saúde</option>
              <option value="physical-conditioning">
                Condicionamento Fisíco
              </option>
              <option value="injury-prevention">Prevensão de lesões</option>
              <option value="definition">Definição</option>
            </Input.Select>
            <Input.Select
              placeholder="Atualmente pratica alguma atividade física regular, pelo menos 3x na semana?"
              name="regular"
              leftElement={<img src={ArrowRight} alt="Arrow icon" />}
              register={register}
              defaultValue="empty-disabled"
            >
              <option value="sim">Sim</option>
              <option value="nao">Não</option>
            </Input.Select>

            <div
              className={
                regular == "sim"
                  ? "anamnese__regular_activity"
                  : "anamnese__regular_activity_hidden"
              }
            >
              <Input.Textarea
                placeholder="Qual? Descreva"
                name="regular-activity"
                register={register}
              ></Input.Textarea>
            </div>

            <Input.Select
              placeholder="Você se compromete a treinar quantas vezes na semana com a Smart Training?"
              name="weekly_count"
              leftElement={<img src={ArrowRight} alt="Arrow icon" />}
              register={register}
              defaultValue="empty-disabled"
            >
              <option value="1x">1 vez</option>
              <option value="2x">2 vezes</option>
              <option value="3x">3 vezes</option>
              <option value="4x">4 vezes</option>
              <option value="5x">5 vezes</option>
              <option value="6x">6 vezes</option>
            </Input.Select>

            <div className="form__submit-button">
              <Button type="submit" isFullWidth>
                Próximo
              </Button>
            </div>
          </form>
        </div>
        {/* <button
          onClick={() => history.push("/anamnese/2")}
          class="anamnese__progress"
        >
          <svg>
            <rect style={{ "stroke-dasharray": "340" }}></rect>
          </svg>
        
          GO
        </button> */}
      </div>
    </section>
  );
}
