import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { message } from "antd";

// Context
import { useAuth } from "../../context/auth";

// Services
import api from "../../services/api";

// Assets
import HomeIcon from "../../assets/icons/navigation/home-icon.svg";
import HomeFillIcon from "../../assets/icons/navigation/home-filled-icon.svg";
import ChartIcon from "../../assets/icons/navigation/chart-icon.svg";
import ChartFillIcon from "../../assets/icons/navigation/chart-filled-icon.svg";
import FlashIcon from "../../assets/images/navigation/flash.png";
import UserIcon from "../../assets/icons/navigation/user-icon.svg";
import UserFillIcon from "../../assets/icons/navigation/user-filled-icon.svg";
import ConfigIcon from "../../assets/icons/navigation/settings-icon.svg";
import ConfigFillIcon from "../../assets/icons/navigation/settings-filled-icon.svg";

export const Navigation = ({ active }) => {
  // Hooks
  const { user } = useAuth();
  const history = useHistory();

  const { data } = useQuery(
    ["training"],
    async () => {
      const { data } = await api.get(
        `/clients/${user.owner_id}/trainings/todays-training`
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  async function handleUserTraining() {
    if (!data)
      return message.warning("Você não possui treino cadastrado no momento!");

    history.push(`training`, { training: data });
  }

  return (
    <div className="navigation">
      <button
        className={`navigation__button${active === "feed" ? " active" : ""}`}
        onClick={() => history.push("/feed")}
      >
        <img
          src={active === "feed" ? HomeFillIcon : HomeIcon}
          alt="Home icon"
        />
      </button>
      <button
        onClick={() => history.push("/instructor")}
        className={`navigation__button${
          active === "instructor" ? " active" : ""
        }`}
      >
        <img
          src={active === "instructor" ? UserFillIcon : UserIcon}
          alt="User icon"
        />
      </button>
      <div />
      <button
        className="navigation__button-center"
        onClick={handleUserTraining}
      >
        <img src={FlashIcon} alt="Chart icon" />
      </button>
      <button
        onClick={() => history.push("/statistics")}
        className={`navigation__button${
          active === "statistics" ? " active" : ""
        }`}
      >
        <img
          src={active === "statistics" ? ChartFillIcon : ChartIcon}
          alt="Chart icon"
        />
      </button>
      <button
        className={`navigation__button${
          active === "settings" ? " active" : ""
        }`}
        onClick={() => history.push("/settings")}
      >
        <img
          src={active === "settings" ? ConfigFillIcon : ConfigIcon}
          alt="Config icon"
        />
      </button>
    </div>
  );
};
