import { Route, Redirect } from "react-router-dom";

// Context
import { useAuth } from "../context/auth";

export default function RouterWrapper({
  component,
  isPrivate,
  isGuest,
  ...rest
}) {
  // Auth Hook
  const { user } = useAuth();

  if (!user && isPrivate) return <Redirect to="/" />;

  if (!!user && isGuest) return <Redirect to="/feed" />;

  return <Route {...rest} component={component} />;
}
