export const exerciseType = (value, type) => {
  const types = {
    RD: `${value}x`,
    T: `${value}s`,
    F: "Falha",
    FT: "Falha Total",
    SM: "Sub-Máximo",
    default: `${value}`,
  };

  return types[type] || types.default;
};
