import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useMutation } from "react-query";
import { message } from "antd";

// Services
import api from "../../services/api";
import { queryClient } from "../../services/queryClient";

// Components
import { Header } from "../../components/ui/header";
import { Input } from "../../components/forms/Input";
import { Button } from "../../components/forms/Button";
import { Navigation } from "../../components/ui/navigation";

// Assets
import ArrowLeft from "../../assets/icons/arrow-left-icon.svg";
import UsersIcon from "../../assets/icons/users-icon.svg";
import CalendarIcon from "../../assets/icons/calendar-icon.svg";
import ScaleIcon from "../../assets/icons/scale-icon.svg";
import SwapIcon from "../../assets/icons/swap-icon.svg";

// Form schema
const schema = yup
  .object({
    gender: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo sexo é obrigatório",
        (field) => field !== "empty-disabled"
      ),
    birthdate: yup.string().required("Campo aniversário é obrigatório"),
    weight: yup.string().required("Campo peso é obrigatório"),
    height: yup.string().required("Campo altura é obrigatório"),
  })
  .required();

export function EditProfile({ location }) {
  const { user_id } = location.state;

  const { data } = useQuery(
    ["user_profile", user_id],
    async () => {
      const { data } = await api.get(`/clients/${user_id}`);

      const profileInfos = {
        gender: data.gender,
        birthdate: new Date(data.birthdate).toISOString().substr(0, 10),
        height: data.height,
        weight: data.weight,
      };

      setValue("gender", data.gender);
      setValue(
        "birthdate",
        new Date(data.birthdate).toISOString().substr(0, 10)
      );
      setValue("height", data.height);
      setValue("weight", data.weight);

      return profileInfos;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  const updateUser = useMutation(
    async ({ gender, birthdate, height, weight }) => {
      await api.put(`/clients/${user_id}`, {
        gender,
        birthdate,
        height,
        weight,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
        queryClient.invalidateQueries("user_profile");
        message.success("Dados alterados com sucesso!");
        history.goBack();
      },
      onError: () =>
        message.error(
          "Ops, algo de errado aconteceu. Tente novamente mais tarde!"
        ),
    }
  );

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      gender: data?.gender,
      birthdate: data?.birthdate,
      weight: data?.weight,
      height: data?.height,
    },
  });
  const history = useHistory();

  const onSubmit = async (data) => updateUser.mutateAsync(data);

  return (
    <section className="signup-profile-step container">
      <Header
        icon={ArrowLeft}
        title="Informações pessoais"
        handleButtonClick={() => history.goBack()}
      />
      <form
        className="signup-profile-step__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input.Select
          placeholder="Selecione seu sexo"
          name="gender"
          leftElement={<img src={UsersIcon} alt="Users icon" />}
          required
          isErrored={!!errors.gender}
          errorMessage={errors.gender?.message}
          register={register}
          defaultValue="empty-disabled"
        >
          <option value="female">Feminino</option>
          <option value="male">Masculino</option>
        </Input.Select>
        <Input
          placeholder="Data de nascimento"
          name="birthdate"
          type="date"
          leftElement={<img src={CalendarIcon} alt="Calendar icon" />}
          isErrored={!!errors.birthdate}
          errorMessage={errors.birthdate?.message}
          register={register}
        />
        <div className="form__input-box">
          <Input
            placeholder="Seu peso"
            name="weight"
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            leftElement={<img src={ScaleIcon} alt="Scale icon" />}
            required
            isErrored={!!errors.weight}
            errorMessage={errors.weight?.message}
            register={register}
          />
          <div className="box">
            <span>KG</span>
          </div>
        </div>
        <div className="form__input-box">
          <Input
            placeholder="Sua altura"
            name="height"
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            leftElement={<img src={SwapIcon} alt="Swap icon" />}
            required
            isErrored={!!errors.height}
            errorMessage={errors.height?.message}
            register={register}
          />
          <div className="box">
            <span>CM</span>
          </div>
        </div>
        <div className="form__submit-button">
          <Button type="submit" isLoading={updateUser.isLoading} isFullWidth>
            Editar
          </Button>
        </div>
      </form>
      <div className="anamnese__button">
        <Button
          type="button"
          isFullWidth
          onClick={() => history.push("/anamnese/1")}
        >
          Editar Anamnese
        </Button>
      </div>
      <Navigation active="settings" />
    </section>
  );
}
