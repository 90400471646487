import { useHistory } from "react-router-dom";
import { Popover } from "antd";

// Data
import { exerciseType } from "../data/domain";

// Components
import { Header } from "../components/ui/header";
import { Button } from "../components/forms/Button";

// Assets
import TrainingIlustration from "../assets/images/training-ilustration.svg";
import ArrowLeft from "../assets/icons/arrow-left-icon.svg";
import SwapIcon from "../assets/icons/swap-icon.svg";
import ClockIcon from "../assets/icons/clock-icon.svg";
import ArrowRight from "../assets/icons/arrow-right-icon.svg";

export function Training({ location }) {
  const training = location?.state?.training;

  // Hooks
  const history = useHistory();

  function handleRedirectExercise(exerciseId) {
    history.push(`/exercise?exercise_id=${exerciseId}`);
  }

  function handleFinishTraining() {
    history.push(`/submit-training?training_id=${training.id}`);
  }

  return (
    <section className="training-page">
      <Header
        icon={ArrowLeft}
        handleButtonClick={() => history.goBack()}
        className="training-page__head"
      />
      <div className="training-page__top">
        <img
          className="top__ilustration"
          src={TrainingIlustration}
          alt="Training ilustration"
        />
      </div>
      <div className="training-page__content">
        <div className="content__slash" />
        <div className="content__infos">
          <h1 className="infos__title">{training?.name}</h1>
          {/* TODO */}
          {/* <p className="infos__text">11 Exercises</p> */}
        </div>
        <div className="content__difficulty">
          <div className="difficulty__bg" />
          <div className="difficulty__icon">
            <img src={SwapIcon} alt="Swap icon" />
            <p className="difficulty__text">Dificuldade</p>
          </div>
          <p className="difficulty__text">Fácil</p>
        </div>
        <div className="content__training">
          <div className="training__top">
            <h3 className="top__title">Exercícios</h3>
            <p className="top__text">{`${training?.steps?.length} blocos`}</p>
          </div>
          {training?.steps?.map((step, index) => (
            <div className="training__training-set" key={step.id}>
              <div className="training-set__top">
                <p className="training-set__title">{step?.name}</p>
                {step?.description && (
                  <div className="training-set__popover">
                    <Popover
                      content={step?.description}
                      placement="left"
                      trigger="click"
                      arrowPointAtCenter
                    >
                      <button>?</button>
                    </Popover>
                  </div>
                )}
              </div>
              {step?.exercises?.map((exercise, index) => (
                <>
                  <div
                    className="training-set__exercise"
                    key={exercise.id}
                    onClick={() =>
                      handleRedirectExercise(exercise?.exercise.id)
                    }
                  >
                    <div className="exercise__left">
                      <img
                        className="exercise__image"
                        src={exercise?.exercise?.thumbnail_url}
                        alt={exercise?.exercise?.thumbnail}
                      />
                      <div className="exercise__description">
                        <h4 className="description__title">
                          {exercise?.exercise?.name}
                        </h4>
                        <p className="description__text">{`${exerciseType(
                          exercise?.value,
                          exercise?.type
                        )} ${
                          exercise?.exercise?.clients[0]?.weight
                            ? `| Último: ${exercise?.exercise?.clients[0]?.weight}kg`
                            : `${
                                exercise?.suggested_weight
                                  ? `| Sugerido: ${exercise?.suggested_weight}kg`
                                  : ""
                              }`
                        }`}</p>
                      </div>
                    </div>
                    <div className="exercise__right">
                      <img src={ArrowRight} alt="Arrow right icon" />
                    </div>
                  </div>
                  {step.exercises.length !== index + 1 && (
                    <div className="training-set__exercise-interval">
                      <p>{`${exercise?.break_between_exercise}s`}</p>
                    </div>
                  )}
                </>
              ))}
              {step?.series && step?.series > 1 && (
                <>
                  <div className="training-set__repeat">
                    <div>
                      <img src={SwapIcon} alt="Swap icon" />
                      <p>Repetir esse bloco {step?.series} vezes</p>
                    </div>
                  </div>
                  {step?.break && step?.break > 0 && (
                    <div className="training-set__repeat">
                      <div>
                        <img src={ClockIcon} alt="Swap icon" />
                        <p>Tempo de intervalo entre series: {step?.break}s</p>
                      </div>
                    </div>
                  )}
                </>
              )}

              {!(index === training.steps.length - 1) && (
                <hr className="training-set__divider" />
              )}
            </div>
          ))}
          <div className="training__finish-button">
            <Button isFullWidth onClick={handleFinishTraining}>
              Finalizar treino
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
