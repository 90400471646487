import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";

//To get the step from url query
import { useParams } from "react-router-dom";

// Services
import api from "../../services/api";

// Context
import { useAuth } from "../../context/auth";

//steps
import One from "../../components/steps/One";
import Two from "../../components/steps/Two";
import Three from "../../components/steps/Three";
import Four from "../../components/steps/Four";

export function Anamnese() {
  // Hooks
  const { user: sessionUser } = useAuth();
  const history = useHistory();

  const { step } = useParams();
  console.log(step);

  console.log(sessionUser);

  const chooseStep = () => {
    switch (step) {
      case "1":
        return <One />;
      case "2":
        return <Two />;
      case "3":
        return <Three />;
      case "4":
        return <Four />;

      default:
        return <h1>No project match</h1>;
    }
  };

  return <div>{chooseStep()}</div>;
}
