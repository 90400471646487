import { Switch } from "react-router-dom";

import ScrollToTop from "../components/scrollToTop";
import Route from "./route";

// Pages
import { Login } from "../pages/login";
import { Account } from "../pages/account";
import { Profile } from "../pages/profile";
import { Goal } from "../pages/goal";
import { Success } from "../pages/success";
import { Training } from "../pages/training";
import { FinishedTraining } from "../pages/finished-training";
import { SubmitTraining } from "../pages/submit-training";
import { Exercise } from "../pages/exercise";
import { Feed } from "../pages/feed";
import { Instructor } from "../pages/instructor";
import { Settings } from "../pages/settings";
import { EditAccount } from "../pages/settings/account";
import { EditProfile } from "../pages/settings/profile";
import { EditGoal } from "../pages/settings/goal";
import { EditPassword } from "../pages/settings/password";
import { Statistics } from "../pages/statistics";
import { StatisticsTraining } from "../pages/statistics-training";
import { Anamnese } from "../pages/anamnese";

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route component={Login} isGuest path="/" exact />
        <Route component={Account} isGuest path="/signup" />
        <Route component={Profile} isGuest path="/signup-profile" />
        <Route component={Goal} isGuest path="/signup-goal" />
        <Route component={Success} isGuest path="/signup-success" />
        <Route component={Training} isPrivate path="/training" />
        <Route component={Exercise} isPrivate path="/exercise" />
        <Route
          component={FinishedTraining}
          isPrivate
          path="/finished-training"
        />
        <Route component={SubmitTraining} isPrivate path="/submit-training" />
        <Route component={Feed} isPrivate path="/feed" />
        <Route component={Instructor} isPrivate path="/instructor" />
        <Route component={EditAccount} isPrivate path="/settings/account" />
        <Route component={EditProfile} isPrivate path="/settings/profile" />
        <Route component={EditGoal} isPrivate path="/settings/goal" />
        <Route component={EditPassword} isPrivate path="/settings/password" />
        <Route component={Settings} isPrivate path="/settings" />
        <Route
          component={StatisticsTraining}
          isPrivate
          path="/statistics/training"
        />
        <Route component={Statistics} isPrivate path="/statistics" />
        <Route component={Anamnese} isPrivate path="/anamnese/:step" />
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
