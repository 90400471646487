import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import Avatar from "react-avatar";

// Services
import api from "../../services/api";

// Context
import { useAuth } from "../../context/auth";

// Components
import { Header } from "../../components/ui/header";
import { Button } from "../../components/forms/Button";

//images
import IlustrationStepTwo from "../../assets/images/anamnese/anamnese_step_3.svg";

//icons
import ArrowLeft from "../../assets/icons/arrow-left-icon.svg";

export default function One() {
  // Hooks
  const { user: sessionUser } = useAuth();
  const history = useHistory();

  console.log(sessionUser);
  return (
    <section className="anamnese">
      <Header
        icon={ArrowLeft}
        title="Voltar"
        className="anamnese__header"
        handleButtonClick={() => history.goBack()}
      />
      <div className="anamnese__ilustration">
        <img src={IlustrationStepTwo} className="ilustration_image" />
      </div>
      <div className="anamnese__questions">
        <button
          onClick={() => history.push("/anamnese/4")}
          class="anamnese__progress"
        >
          <svg>
            <rect style={{ "stroke-dasharray": "420" }}></rect>
          </svg>
          GO
        </button>
      </div>
    </section>
  );
}
